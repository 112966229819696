import { Link } from "gatsby";
import * as React from "react";

interface PaginationProps {
  totalCount: number;
}

export const Pagination: React.FC<PaginationProps> = ({ totalCount }) => {
  const PER_PAGE = 5;

  const range = (start: number, end: number) =>
    [...Array(end - start + 1)].map((_, i) => start + i);

  return (
    <ul>
      {range(1, Math.ceil(totalCount / PER_PAGE)).map((number, index) => (
        <li key={index}>
          <Link to={`/post/page/${number}`}>{number}</Link>
        </li>
      ))}
    </ul>
  );
};
