import * as React from "react";
import { graphql, HeadFC, Link, PageProps } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";
import { Pagination } from "components/Pagination";

const PostListPage: React.FC<PageProps<Queries.PaginatedPostListQuery>> = ({
  data,
  pageContext,
}) => {
  console.log({ pageContext });

  return (
    <Layout>
      <h1 className="text-3xl font-bold underline">投稿一覧</h1>
      <ul>
        {data.allMicrocmsPosts.nodes.map((node) => {
          return (
            <li>
              <Link to={`/post/${node.postsId}`}>
                <span>{node.title}</span>
                <time>（公開日：{node.publishedAt}）</time>
                <span>{node.author}</span>
              </Link>
            </li>
          );
        })}
      </ul>
      <Pagination totalCount={data.allMicrocmsPosts.totalCount} />
    </Layout>
  );
};

export const query = graphql`
  query PaginatedPostList($limit: Int!, $skip: Int!) {
    allMicrocmsPosts(limit: $limit, skip: $skip) {
      nodes {
        title
        postsId
        publishedAt(formatString: "YYYY/MM/DD")
        author
      }
      totalCount
    }
  }
`;

export default PostListPage;

export const Head: HeadFC = () => <Seo title="投稿一覧" />;
